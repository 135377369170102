import React from 'react'
import Link from 'next/link'

import SignoutButton from './signout-button'
import { User as UserIcon } from "lucide-react"
import { Button } from './ui/button'
import { User } from '@prisma/client'
import { NavigationMenuLink, NavigationMenuItem } from './ui/navigation-menu'

const UserSection = ({ user }: { user: User }) => {
  return (
    <div className='flex flex-col w-full'>
      <h6 className='flex gap-4 items-center'>
        <UserIcon className='w-4 h-4' />{user.firstName} {user.name}
      </h6>
      <NavigationMenuItem>
        <Link href='/profile' legacyBehavior passHref>
          <Button className='w-full font-bold'>
            <NavigationMenuLink>
              Ma Page
            </NavigationMenuLink>
          </Button>
        </Link>
      </NavigationMenuItem>

      <NavigationMenuItem>
        <NavigationMenuLink className='w-full'>
          <SignoutButton className='w-full' />
        </NavigationMenuLink>
      </NavigationMenuItem>
    </div>
  )
}

export default UserSection
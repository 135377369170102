"use client"
import { usePathname } from "next/navigation"
import Link from 'next/link'

import { ExtendedUser as User } from "@/lib/user"
import { ExtendedProject as Project } from "@/lib/project"

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList
} from "@/components/ui/navigation-menu"
import { Construction, Folders, Landmark, LockKeyhole, NotepadText, Plus, ScrollText } from "lucide-react"
import { ToggleTheme } from "./provider/toggle-theme"
import { Button } from "./ui/button"


import Image from "next/image"
import UserSection from "./user-section"


const NavBar = ({ user, className, projects }: { user?: User, className?: string, projects: Project[] }) => {
  const pathname = usePathname()

  const projectsInProgress = projects.filter(project => project.status === 'INVOICE_SIGNED')

  return (
    <nav className={user ? '' : 'hidden'}>
      <NavigationMenu orientation="vertical" className={className + ' items-start h-full overflow-auto border-r'}>
        <NavigationMenuList className='flex flex-col gap-3 items-start p-2 w-screen sm:w-56'>
          <div className="flex gap-4 items-center">
            <div className="relative w-12 h-12">
              <Image src="/Logo.png" alt="MoneoDomus" fill className="object-contain" />
            </div>
            <h3 className='m-0'>Moneo Domus</h3>
          </div>

          {/* <NavigationMenuItem className={pathname === '/' ? 'bg-accent/40 text-popover-foreground' : ''}>
            <Link href="/" className="w-full" legacyBehavior passHref >
              <NavigationMenuLink className="w-full font-bold">
                <Home className="w-4 h-4" />Home
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem> */}

          {user?.agencies && user?.agencies?.length > 0 &&
            user.agencies.map(agency => {
              return (
                <NavigationMenuItem key={agency.id}>
                  <Link href={`/agence/${agency.slug}`} className="w-full" legacyBehavior passHref>
                    <Button variant="outline" className={`w-full ${pathname === `/agence/${agency.slug}` ? 'bg-primary text-primary-foreground' : ''}`}>
                      <NavigationMenuLink className="w-full font-bold">
                        <Landmark className="w-4 h-4" /> {agency.name}
                      </NavigationMenuLink>
                    </Button>
                  </Link>
                </NavigationMenuItem>
              )
            })
          }

          <div className="p-2 bg-card rounded w-full">
            <h6 className="flex gap-4 items-center">
              <Folders className="w-4 h-4" /> Demandes
            </h6>

            <NavigationMenuItem className={pathname === '/demandes' ? 'bg-card/40 text-popover-foreground' : ''}>
              <Link href="/demandes" className="w-full" legacyBehavior passHref>
                <Button variant="outline" className={`w-full ${pathname === '/demandes' ? 'bg-primary text-primary-foreground' : ''}`}>
                  <NavigationMenuLink className="w-full font-bold">
                    <ScrollText className="w-4 h-4" /> Consulter
                  </NavigationMenuLink>
                </Button>
              </Link>
            </NavigationMenuItem>

            <NavigationMenuItem className={pathname === '/nouvelle-demande' ? 'bg-card/40 text-popover-foreground' : ''}>
              <Link href="/nouvelle-demande" className="w-full" legacyBehavior passHref>
                <Button variant="outline" className={`w-full ${pathname === '/nouvelle-demande' ? 'bg-primary text-primary-foreground' : ''}`}>
                  <NavigationMenuLink className="w-full font-bold">
                    <Plus className="w-4 h-4" /> Créer
                  </NavigationMenuLink>
                </Button>
              </Link>
            </NavigationMenuItem>
          </div>

          {projectsInProgress.length > 0 && user?.role === 'SUPER_ADMIN' &&
            <div className="p-2 bg-secondary rounded w-full">
              <h6 className="flex gap-4 items-center">
                <Construction className="w-4 h-4" />
                Chantiers
              </h6>
              {projectsInProgress.map(project => (
                <NavigationMenuItem key={project.id}>
                  <Link href={`/dossier-chantier/${project.id}`} className="w-full" legacyBehavior passHref>
                    <Button variant="outline" className={`w-full h-auto ${pathname === `/dossier-chantier/${project.id}` ? 'bg-secondary text-secondary-foreground' : ''}`}>
                      <NavigationMenuLink className="w-full flex items-center h-auto">
                        <NotepadText className="flex-shrink-0 w-4 h-4" />
                        <span className="text-left whitespace-normal break-words flex-grow">{project.adresseChantier}</span>
                      </NavigationMenuLink>
                    </Button>
                  </Link>
                </NavigationMenuItem>
              ))}
            </div>
          }

          {user?.role === 'SUPER_ADMIN' &&
            <NavigationMenuItem>
              <Link href="/admin" className="w-full" legacyBehavior passHref>
                <Button
                  variant="outline"
                  className={`${pathname === '/admin' ? 'bg-destructive/80 text-destructive-foreground' : ''} w-full`}
                >
                  <NavigationMenuLink className="w-full font-bold">
                    <LockKeyhole className="w-4 h-4" /> Admin
                  </NavigationMenuLink>
                </Button>
              </Link>
            </NavigationMenuItem>
          }

          {user &&
            <div className="w-full p-2 bg-card rounded">
              <UserSection user={user} />
            </div>
          }

          <NavigationMenuItem>
            <ToggleTheme />
          </NavigationMenuItem>

        </NavigationMenuList>
      </NavigationMenu >
    </nav >
  )
}

export default NavBar
import { type ClassValue, clsx } from "clsx"
import slugify from "slugify"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export async function generateSlug(title: string, model: any) {
  let slug = slugify(title, { lower: true })
  let originalSlug = slug

  if (await model.findUnique({ where: { slug } })) {
    let count = 1
    while (await model.findUnique({ where: { slug } })) {
      slug = `${originalSlug}-${count}`
      count++
    }
  }
  return slug
}
"use client"
import React from 'react'
import { signOut } from "next-auth/react"
import { Button } from "@/components/ui/button"
import { LogOut } from 'lucide-react'


const SignoutButton = ({ className }: { className?: string }) => {
  return (
    <Button onClick={() => signOut()} variant="destructive" className={`flex items-center gap-2 ${className}`}>
      <LogOut className="w-4 h-4" /> <p className="font-bold">Me déconnecter</p>
    </Button>
  )
}

export default SignoutButton